import payload_plugin_e0k4tW9bdI from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "H:/ssd2/vkusilosos-web-main/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "H:/ssd2/vkusilosos-web-main/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import defaults_9rtUot57OJ from "H:/ssd2/vkusilosos-web-main/node_modules/@nuxtseo/module/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import defaults_TgNupsGd8e from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/defaults.mjs";
import nuxt3_plugin_BdfRvWAAOY from "H:/ssd2/vkusilosos-web-main/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_client_mik7N71GmK from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "H:/ssd2/vkusilosos-web-main/.nuxt/vuefire-plugin.mjs";
import motion_fmZWau4t0U from "H:/ssd2/vkusilosos-web-main/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_UciE0i6zes from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "H:/ssd2/vkusilosos-web-main/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _01_vue_query_k8pj2QgNCo from "H:/ssd2/vkusilosos-web-main/plugins/01.vue-query.ts";
import _02_axios_7qlkKZMuUY from "H:/ssd2/vkusilosos-web-main/plugins/02.axios.ts";
import _03_toasts_client_yjONJQib5M from "H:/ssd2/vkusilosos-web-main/plugins/03.toasts.client.ts";
import _05_yup_locale_client_LRsCHbIlcy from "H:/ssd2/vkusilosos-web-main/plugins/05.yup-locale.client.ts";
import _06_fcm_sw_client_QUM4SL6FIi from "H:/ssd2/vkusilosos-web-main/plugins/06.fcm-sw.client.ts";
import _07_maska_client_5PymwnkPLG from "H:/ssd2/vkusilosos-web-main/plugins/07.maska.client.ts";
import _08_vue_tippy_jxstlIVtY4 from "H:/ssd2/vkusilosos-web-main/plugins/08.vue-tippy.ts";
import init_74KpgUZBCI from "H:/ssd2/vkusilosos-web-main/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/init.mjs";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  defaults_9rtUot57OJ,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  defaults_TgNupsGd8e,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_client_mik7N71GmK,
  vuefire_plugin_8cq4FnzXKb,
  motion_fmZWau4t0U,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  _01_vue_query_k8pj2QgNCo,
  _02_axios_7qlkKZMuUY,
  _03_toasts_client_yjONJQib5M,
  _05_yup_locale_client_LRsCHbIlcy,
  _06_fcm_sw_client_QUM4SL6FIi,
  _07_maska_client_5PymwnkPLG,
  _08_vue_tippy_jxstlIVtY4,
  init_74KpgUZBCI
]