import { default as _91_46_46_46slug_93NyKQBGOYWTMeta } from "H:/ssd2/vkusilosos-web-main/pages/[...slug].vue?macro=true";
import { default as _91dish_slug_93LKc71oT6LKMeta } from "H:/ssd2/vkusilosos-web-main/pages/[category_slug]/[dish_slug].vue?macro=true";
import { default as indexbx7gFyI8wpMeta } from "H:/ssd2/vkusilosos-web-main/pages/[category_slug]/index.vue?macro=true";
import { default as _91id_938JCzSE8LMMMeta } from "H:/ssd2/vkusilosos-web-main/pages/blog/[id].vue?macro=true";
import { default as indexzhYLBZ1b1nMeta } from "H:/ssd2/vkusilosos-web-main/pages/blog/index.vue?macro=true";
import { default as delivery_conditionsF7Fmby8CYTMeta } from "H:/ssd2/vkusilosos-web-main/pages/delivery_conditions.vue?macro=true";
import { default as index4j8EAh75JcMeta } from "H:/ssd2/vkusilosos-web-main/pages/index.vue?macro=true";
import { default as not_45foundKMtw1Q6vi2Meta } from "H:/ssd2/vkusilosos-web-main/pages/not-found.vue?macro=true";
import { default as privacy_policyDXUQ3ib2fLMeta } from "H:/ssd2/vkusilosos-web-main/pages/privacy_policy.vue?macro=true";
import { default as _91id_9312sxBeKKOuMeta } from "H:/ssd2/vkusilosos-web-main/pages/promo/[id].vue?macro=true";
import { default as indexSlHuWdG7Q3Meta } from "H:/ssd2/vkusilosos-web-main/pages/promo/index.vue?macro=true";
import { default as public_offertadey5AJInTQMeta } from "H:/ssd2/vkusilosos-web-main/pages/public_offerta.vue?macro=true";
import { default as user_agreementiQ82VzXNc2Meta } from "H:/ssd2/vkusilosos-web-main/pages/user_agreement.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93NyKQBGOYWTMeta?.name ?? "slug",
    path: _91_46_46_46slug_93NyKQBGOYWTMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93NyKQBGOYWTMeta || {},
    alias: _91_46_46_46slug_93NyKQBGOYWTMeta?.alias || [],
    redirect: _91_46_46_46slug_93NyKQBGOYWTMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91dish_slug_93LKc71oT6LKMeta?.name ?? "category_slug-dish_slug",
    path: _91dish_slug_93LKc71oT6LKMeta?.path ?? "/:category_slug()/:dish_slug()",
    meta: _91dish_slug_93LKc71oT6LKMeta || {},
    alias: _91dish_slug_93LKc71oT6LKMeta?.alias || [],
    redirect: _91dish_slug_93LKc71oT6LKMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/[category_slug]/[dish_slug].vue").then(m => m.default || m)
  },
  {
    name: indexbx7gFyI8wpMeta?.name ?? "category_slug",
    path: indexbx7gFyI8wpMeta?.path ?? "/:category_slug()",
    meta: indexbx7gFyI8wpMeta || {},
    alias: indexbx7gFyI8wpMeta?.alias || [],
    redirect: indexbx7gFyI8wpMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/[category_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938JCzSE8LMMMeta?.name ?? "blog-id",
    path: _91id_938JCzSE8LMMMeta?.path ?? "/blog/:id()",
    meta: _91id_938JCzSE8LMMMeta || {},
    alias: _91id_938JCzSE8LMMMeta?.alias || [],
    redirect: _91id_938JCzSE8LMMMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzhYLBZ1b1nMeta?.name ?? "blog",
    path: indexzhYLBZ1b1nMeta?.path ?? "/blog",
    meta: indexzhYLBZ1b1nMeta || {},
    alias: indexzhYLBZ1b1nMeta?.alias || [],
    redirect: indexzhYLBZ1b1nMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: delivery_conditionsF7Fmby8CYTMeta?.name ?? "delivery_conditions",
    path: delivery_conditionsF7Fmby8CYTMeta?.path ?? "/delivery_conditions",
    meta: delivery_conditionsF7Fmby8CYTMeta || {},
    alias: delivery_conditionsF7Fmby8CYTMeta?.alias || [],
    redirect: delivery_conditionsF7Fmby8CYTMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/delivery_conditions.vue").then(m => m.default || m)
  },
  {
    name: index4j8EAh75JcMeta?.name ?? "index",
    path: index4j8EAh75JcMeta?.path ?? "/",
    meta: index4j8EAh75JcMeta || {},
    alias: index4j8EAh75JcMeta?.alias || [],
    redirect: index4j8EAh75JcMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: not_45foundKMtw1Q6vi2Meta?.name ?? "not-found",
    path: not_45foundKMtw1Q6vi2Meta?.path ?? "/not-found",
    meta: not_45foundKMtw1Q6vi2Meta || {},
    alias: not_45foundKMtw1Q6vi2Meta?.alias || [],
    redirect: not_45foundKMtw1Q6vi2Meta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: privacy_policyDXUQ3ib2fLMeta?.name ?? "privacy_policy",
    path: privacy_policyDXUQ3ib2fLMeta?.path ?? "/privacy_policy",
    meta: privacy_policyDXUQ3ib2fLMeta || {},
    alias: privacy_policyDXUQ3ib2fLMeta?.alias || [],
    redirect: privacy_policyDXUQ3ib2fLMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/privacy_policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_9312sxBeKKOuMeta?.name ?? "promo-id",
    path: _91id_9312sxBeKKOuMeta?.path ?? "/promo/:id()",
    meta: _91id_9312sxBeKKOuMeta || {},
    alias: _91id_9312sxBeKKOuMeta?.alias || [],
    redirect: _91id_9312sxBeKKOuMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/promo/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSlHuWdG7Q3Meta?.name ?? "promo",
    path: indexSlHuWdG7Q3Meta?.path ?? "/promo",
    meta: indexSlHuWdG7Q3Meta || {},
    alias: indexSlHuWdG7Q3Meta?.alias || [],
    redirect: indexSlHuWdG7Q3Meta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/promo/index.vue").then(m => m.default || m)
  },
  {
    name: public_offertadey5AJInTQMeta?.name ?? "public_offerta",
    path: public_offertadey5AJInTQMeta?.path ?? "/public_offerta",
    meta: public_offertadey5AJInTQMeta || {},
    alias: public_offertadey5AJInTQMeta?.alias || [],
    redirect: public_offertadey5AJInTQMeta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/public_offerta.vue").then(m => m.default || m)
  },
  {
    name: user_agreementiQ82VzXNc2Meta?.name ?? "user_agreement",
    path: user_agreementiQ82VzXNc2Meta?.path ?? "/user_agreement",
    meta: user_agreementiQ82VzXNc2Meta || {},
    alias: user_agreementiQ82VzXNc2Meta?.alias || [],
    redirect: user_agreementiQ82VzXNc2Meta?.redirect,
    component: () => import("H:/ssd2/vkusilosos-web-main/pages/user_agreement.vue").then(m => m.default || m)
  }
]